import React from 'react'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'
import PageHelmet from '../components/pageHelmet'

import forBizHeroImg from '../images/hero-kwwl-news.jpg'
import forBizSvg from '../images/our-story.svg'
import headshotDavidKrupp from '../images/david-krupp.jpg'
import headshotRobbieSchwenker from '../images/robbie-schwenker.jpg'

import volunteerHollis from '../images/volunteer-hollis-neal.svg'
import volunteerRevive from '../images/volunteer-revive.svg'
import volunteerKatie from '../images/volunteer-katie-white.svg'

import volunteerThompson from '../images/volunteer-thompson.svg'
import volunteerClarissa from '../images/volunteer-clarissa-koch.svg'
import volunteerEDC from '../images/volunteer-edc.svg'
import volunteerBoyd from '../images/volunteer-boyd.svg'

import volunteerPagliais from '../images/volunteer-pagliais.svg'
import volunteerMeld from '../images/volunteer-meld.svg'
import volunteer30hop from '../images/volunteer-30hop.svg'

const OurStoryPage = () => (
  <Layout>
    <PageHelmet
      title="Our Story"
      description="Launched in May 2020 by two Iowa doctors, 'Mask of Wellness' is a positive approach that promotes responsible behavior"
      image={forBizHeroImg} />
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'Our'}
      accentedTitle={'Story'}
      subtitle={'How the movement started'}
    />

    <section className="section" key="mow-forbiz-graphic">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <p>
              Launched in May 2020 by two Iowa doctors, 'Mask of Wellness' is a
              positive approach that promotes responsible behavior that is
              necessary to keep society open during this pandemic while
              benefiting social, physical, and economic wellness. David Krupp,
              M.D. and Robbie Schwenker, Pharm.D. developed the simple safety
              method and self-certification program to help preserve the
              nation’s economy while at the same time maintaining healthy
              practices for the public.
            </p>
            <br />
            <div class="columns has-text-centered">
              <div class="column">
                <img src={headshotDavidKrupp} className="headshot" />
                <h4 className="has-text-centered bio-names">David C. Krupp, M.D.</h4>
                <h5 className="has-text-centered">ER Physician</h5>
                <p className="has-text-justified bio-text">
                  David is an emergency physician of 20 years experience with a
                  deep respect for his patients and community. He sees firsthand
                  the physical, emotional, and economic impact COVID-19 has had
                  upon the wellness of his patients, families, and community.
                  Through his efforts to mitigate the silent spread of disease
                  he and Dr. Schwenker developed the 'Mask of Wellness'.
                  Normalizing mask-wearing – and even making it cool – will help
                  accomplish the crucial societal paradigm shift needed to
                  mitigate the spread of disease during this pandemic.
                </p>
              </div>
              <div class="column">
                <img src={headshotRobbieSchwenker} className="headshot" />
                <h4 className="has-text-centered bio-names">Robbie Schwenker, Pharm.D.</h4>
                <h5 className="has-text-centered">Pharmacist</h5>
                <p className="has-text-justified bio-text">
                  Robbie, a retail pharmacist with 8 years of experience, serves
                  patients in Coralville and the greater Corridor community.
                  Well acquainted with COVID-19’s local impact, he understands
                  the importance of hygiene practices and healthy work
                  environments. Robbie is passionate about 'Mask of Wellness'
                  potential to empower businesses and build trust in the
                  community during this uncertain time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section is-paddingless mow-border-top">
      <div className="container is-fluid">
        <div className="columns footer-cta-section">
          <div className="column is-9 center-block sign-up-col">
            <h3 className="has-text-centered">Special Thanks To</h3>
            <br />
            <div className="columns is-vcentered is-multiline">
              <div className="column is-4 has-text-centered">
                <a
                  href="https://www.linkedin.com/in/hollis-neal-61688217a/"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="volunteer logo"
                  className="volunteer-logo no-border-bottom"
                >
                  <img src={volunteerHollis} />
                </a>
              </div>
              <div className="column is-4 has-text-centered">
                <a
                  href="https://revivedesignstudios.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="volunteer logo"
                  className="volunteer-logo no-border-bottom"
                >
                  <img src={volunteerRevive} />
                </a>
              </div>
              <div className="column is-4 has-text-centered">
                <a
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="volunteer logo"
                  className="volunteer-logo no-border-bottom"
                >
                  <img src={volunteerKatie} />
                </a>
              </div>

              <div className="column is-3 has-text-centered">
                <a
                  href="https://thompsoncosalon.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="volunteer logo"
                  className="volunteer-logo no-border-bottom"
                >
                  <img src={volunteerThompson} />
                </a>
              </div>
              <div className="column is-3 has-text-centered">
                <a
                  href="https://www.instagram.com/clarissapix/"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="volunteer logo"
                  className="volunteer-logo no-border-bottom"
                >
                  <img src={volunteerClarissa} />
                </a>
              </div>
              <div className="column is-3 has-text-centered">
                <a
                  href="https://www.facebook.com/BCbuildingIowaCity/"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="volunteer logo"
                  className="volunteer-logo no-border-bottom"
                >
                  <img src={volunteerBoyd} />
                </a>
              </div>
              <div className="column is-3 has-text-centered">
                <a
                  href="https://meld.marketing/"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="volunteer logo"
                  className="volunteer-logo no-border-bottom"
                >
                  <img src={volunteerMeld} />
                </a>
              </div>

              <div className="column is-4 has-text-centered">
                <a
                  href="http://www.pagliaisic.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="volunteer logo"
                  className="volunteer-logo no-border-bottom"
                >
                  <img src={volunteerPagliais} />
                </a>
              </div>
              <div className="column is-4 has-text-centered">
                <a
                  href="http://www.edcinc.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="volunteer logo"
                  className="volunteer-logo no-border-bottom"
                >
                  <img src={volunteerEDC} />
                </a>
              </div>
              <div className="column is-4 has-text-centered">
                <a
                  href="https://30hop.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="volunteer logo"
                  className="volunteer-logo no-border-bottom"
                >
                  <img src={volunteer30hop} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="container is-fluid">
        <div className="columns footer-cta-section">
          <div className="column is-8 is-offset-2 sign-up-col">
            <h3 className="has-text-centered">Special Thanks To</h3>

            <nav class="level">
              <div class="level-item has-text-centered">
                <div>
                  <a href="https://www.linkedin.com/in/hollis-neal-61688217a/" target="_blank" rel="noopener noreferrer" alt="volunteer logo" className="volunteer-logo no-border-bottom"><img src={volunteerHollis} /></a>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <a href="https://revivedesignstudios.com" target="_blank" rel="noopener noreferrer" alt="volunteer logo" className="volunteer-logo no-border-bottom"><img src={volunteerRevive} /></a>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <a href="" target="_blank" rel="noopener noreferrer" alt="volunteer logo" className="volunteer-logo no-border-bottom"><img src={volunteerKatie} /></a>
                </div>
              </div>
            </nav>

            <nav class="level">
              <div class="level-item has-text-centered">
                <div>
                  <a href="https://thompsoncosalon.com/" target="_blank" rel="noopener noreferrer" alt="volunteer logo" className="volunteer-logo no-border-bottom"><img src={volunteerThompson} /></a>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <a href="https://www.facebook.com/BCbuildingIowaCity/" target="_blank" rel="noopener noreferrer" alt="volunteer logo" className="volunteer-logo no-border-bottom"><img src={volunteerBoyd} /></a>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <a href="https://meld.marketing/" target="_blank" rel="noopener noreferrer" alt="volunteer logo" className="volunteer-logo no-border-bottom"><img src={volunteerMeld} /></a>
                </div>
              </div>
            </nav>

            <nav class="level">
              <div class="level-item has-text-centered">
                <div>
                  <a href="http://www.pagliaisic.com/" target="_blank" rel="noopener noreferrer" alt="volunteer logo" className="volunteer-logo no-border-bottom"><img src={volunteerPagliais} /></a>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <a href="http://www.edcinc.org/" target="_blank" rel="noopener noreferrer" alt="volunteer logo" className="volunteer-logo no-border-bottom"><img src={volunteerEDC} /></a>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <a href="https://30hop.com/" target="_blank" rel="noopener noreferrer" alt="volunteer logo" className="volunteer-logo no-border-bottom"><img src={volunteer30hop} /></a>
                </div>
              </div>
            </nav>

          </div>
        </div>
      </div>*/}
    </section>
  </Layout>
)

export default OurStoryPage
